* {
  box-sizing: border-box;
}

:root {
  font-family: sans-serif;
  --primary-color: #6c6cfc;
  --primary-color-light: #ebf1ff;
  --secondary-color: #34b984;
  --secondary-color-light: #e7f8f3;
  --auxiliary-color: #384b62;
  --border-and-divider-color: #dbdbdb;
  --error-color: #f77e6e;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: var(--auxiliary-color);
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin-bottom: 1rem;
  margin-top: 0;
}

hr {
  height: 1px;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(150, 150, 150, 0.8);
}

button {
  padding: 8px;
  background: #d5d9ff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  font-weight: bold;
}
button:hover {
  filter: brightness(0.8);
}
button:active {
  filter: brightness(0.6);
}
